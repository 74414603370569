@use "../../variables.module";

.Content {
  display: flex;
  flex-direction: column;
  flex: 1.618;
  padding: 10px;
  overflow-y: auto;
  background: variables.$color-primary;
  @media print {
    background: white !important;
    color: black !important;
    overflow: visible;
    padding: 0;
  }
}
