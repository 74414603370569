@use "../../variables.module.scss";

.Stat {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  &.row {
    .title {
      margin-right: 10px;
    }
  }
  &.column {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .title,
  .value {
    word-wrap: break-word;
  }
  .title {
    color: variables.$color-muted;
  }
  .buttonValue {
    background: none;
    color: variables.$color-secondary-text;
    font-size: 1rem;
    cursor: pointer;
    padding: 0px 5px;
    border: 1px dotted variables.$color-muted;
    line-height: 1.2rem;
    &:hover {
      color: variables.$color-accent;
      border-color: variables.$color-accent;
    }
  }
  .pills {
    display: flex;
    flex-wrap: wrap;
    .pill {
      border: 1px solid variables.$color-muted;
      padding: 5px 10px;
      margin-right: 5px;
      margin-bottom: 5px;
      border-radius: 10px;
      &:hover {
        color: variables.$color-accent;
        border-color: variables.$color-accent;
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media print {
    .buttonValue,
    .title,
    .value {
      color: black;
    }
  }
}
