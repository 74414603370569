@use "../../variables.module.scss";

.Header {
  color: variables.$color-primary-text;
  .button-menu {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    &:hover svg path,
    &.active svg path {
      fill: variables.$color-accent;
    }
  }
  h2 {
    font-size: variables.$font-size-base;
  }
  @media print {
    padding: 20px;
  }
}
