@use "variables.module";
@import "normalize.css";

@mixin dots($count) {
  $text-shadow: ();
  @for $i from 0 through $count {
    $text-shadow: $text-shadow,
      (-0.5+ (random()) * 3) +
        em
        (-0.5+ (random()) * 3) +
        em
        7px
        hsla(random() * 360, 100%, 50%, 0.9);
  }
  text-shadow: $text-shadow;
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: "liga" 1, "calt" 1;
  @supports (font-variation-settings: normal) {
    font-family: InterVariable, sans-serif;
  }
}

html,
body,
#root {
  height: 100%;
}

html {
  font-size: variables.$font-size-base;
}

body {
  background: variables.$color-secondary;
  color: variables.$color-primary-text;
  margin: 0;
  overflow: hidden;
}

h1,
h2,
h3,
p {
  line-height: 1.5rem;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: variables.$color-secondary-text;
  text-decoration: none;
  &:hover,
  &.active {
    text-decoration: underline;
    color: variables.$color-accent;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

@media print {
  body {
    background: white !important;
    color: black !important;
  }
}
