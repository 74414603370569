@use "../../variables.module";

.Navigation {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ul li {
    font-size: variables.$font-size-secondary;
    text-transform: uppercase;
    a {
      display: inline-block;
      padding: 10px;
    }
  }
  @media screen and (max-width: 992px) {
    ul li a {
      font-size: variables.$font-size-base;
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    ul {
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 10px;
      li a {
        display: block;
        color: variables.$color-primary-text;
        padding: 10px;
        background-color: variables.$color-accent;
        color: variables.$color-secondary-text;
      }
      li span {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: variables.$color-accent;
        a {
          display: inline-block;
        }
        svg {
          background: variables.$color-secondary-text;
          border-radius: 15px;
        }
      }
    }
  }
}
